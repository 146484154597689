import { graphql } from 'react-relay/legacy';

export default graphql`
    query sellerDirectoryRootQuery(
        $page: Int!
        $verticals: String
        $company: String
        $isDistinguished: Boolean!
        $isTopSeller: Boolean!
        $contentModulePreviewId: String
    ) {
        viewer {
            ...SellerDirectoryLayout_viewer
        }
    }
`;
